import { PrismicRichText, PrismicText } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import LogoDark from "src/assets/logoDark.svg";
import LogoLight from "src/assets/logoLight.svg";

import { Bounded } from "./Bounded";
import { Heading } from "./Heading";
import { HorizontalDivider } from "./HorizontalDivider";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";

const SignUpForm = ({ settings }) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleFormSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post("/api/sign-up", {
        email,
      });
      toast.success("Obrigado por se inscrever! 🎉");
      setIsSubmitting(false);
      setEmail("");
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error === "Email subscribed") {
        toast.success("Você já está inscrito!");
        setEmail("");
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
      setEmail("");
      toast.error("Ocorreu um erro ao se inscrever. Tente novamente.");
    }
  };
  return (
    <div className="px-4">
      <div className="grid w-full max-w-xl grid-cols-1 gap-6">
        {prismicH.isFilled.richText(settings.data.newsletterDisclaimer) && (
          <div className="text-center font-serif tracking-tight text-slate-500 dark:text-[#f5f4f5]">
            <PrismicRichText
              field={settings.data.newsletterDescription}
              components={{
                heading1: ({ children }) => (
                  <Heading as="h2" className="mb-4 last:mb-0">
                    {children}
                  </Heading>
                ),
                paragraph: ({ children }) => (
                  <p className="mb-4 italic last:mb-0">{children}</p>
                ),
              }}
            />
          </div>
        )}
        <div className="grid grid-cols-1 gap-2">
          <div className="relative">
            <label>
              <span className="sr-only">Email</span>
              <input
                name="email"
                type="email"
                value={email}
                placeholder="exemple@email.com"
                required
                className="w-full rounded-none border-b border-[#18191A] py-3 pl-3 pr-10 text-slate-800 dark:text-[#fff]"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </label>
            <button
              type="button"
              disabled={!email || !isEmail(email) || isSubmitting}
              onClick={handleFormSubmit}
              className="absolute top-0 right-0 bottom-0 flex cursor-pointer items-center justify-center px-3 text-2xl text-slate-400 placeholder-gray-500 hover:text-[#18191A] "
            >
              <span className="sr-only">Submit</span>
              {isSubmitting ? (
                <AiOutlineLoading3Quarters
                  className="animate-spin"
                  color="#fff"
                />
              ) : (
                <span aria-hidden={true}>&rarr;</span>
              )}
            </button>
          </div>
          {prismicH.isFilled.richText(settings.data.newsletterDisclaimer) && (
            <p className="text-center text-xs tracking-tight text-slate-500 dark:text-[#f5f4f5]">
              <PrismicText field={settings.data.newsletterDisclaimer} />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export const Footer = ({ withSignUpForm = true, settings }) => {
  const { theme } = useTheme();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Bounded as="footer">
      <div className="grid grid-cols-1 justify-items-center gap-24">
        <HorizontalDivider />
        {withSignUpForm && <SignUpForm settings={settings} />}
        {theme === "light" ? (
          <LogoLight className="w-[200px]" />
        ) : (
          <LogoDark className="w-[200px]" />
        )}
      </div>
    </Bounded>
  );
};
